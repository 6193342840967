.teaser.magazine {
  &.medium,
  &.large {
    .container {
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
    }
    .title {
      text-align: center;

      @supports (text-wrap: pretty) {
        text-wrap: pretty;
      }
    }
  }

  &.large:not(.feature) {
    padding-bottom: var(--space-8);
  }
}

@media (min-width: 765px) {
  .teaser.magazine {
    &.large {
      .title {
        margin-left: var(--space-6);
        margin-right: var(--space-6);
      }
    }
  }
}
